@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.active {
  background-color: #207384;
  color: white;
}
.modalfloat {
  position: absolute;
  background-color: #c9c6c68c;
  top: 0;
  right: 10;
  left: 0;
  bottom: 0;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.blogfloat {
  position: absolute;
  background-color: #e0f6f7;
  top: 0;
  right: 10;
  left: 0;
  bottom: 0;
  width: 100%;
}

.modalfloat2 {
  position: absolute;
  background-color: #c9c6c68c;
  top: 0;
  right: 10;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hover-effect,
button {
  transform: scale(1);
  transition: transform 0.3s ease;
}

.hover-effect:hover,
button:hover {
  transform: scale(1.2);
}

.progress-bg {
  width: 100%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.076);
  border-radius: 8px;
  margin-top: 8px;
}
.progress {
  width: 0%;
  height: 5px;
  background-color: #207384;
  border-radius: 8px;
  transition: width 0.5s ease;
}
.check-circle {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #207384;
  background-color: #f1efff;
  border: none;
  border-radius: 30px;
}

/* reset pass */
.form1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.formdiv {
  width: 90%;
}

.message1 {
  width: 100%;
  padding: 10px;
}
.message1 h3 {
  font-weight: 700;
  margin-bottom: 20px;
}
.hide {
  display: none;
}
.button1 {
  padding: 10px 20px;
  background-color: #207384;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  cursor: pointer;
}

input:focus,
button:focus {
  outline: none;
}
@media screen and (max-width: 488px) {
  .container {
    width: 90% !important;
  }
  h2 {
    font-size: 16px;
  }
}
.imput-wrap1 {
  width: 100%;
  background-color: #fafaff;
  display: flex;
  gap: 10px;
  padding: 13px 15px;
  align-items: center;
  margin-top: 5px;
}

.input1 {
  width: 100%;
  background: transparent;
  border: none;
  color: #898d9e;
}

.label1 {
  color: #2e2f32;
}
